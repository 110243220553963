import React from 'react';
import Navigation from './components/Navigation';


const About = () => {
    return (
        <div>
            <Navigation/>
            
           <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio magni adipisci sapiente vel at ratione consequatur id
             cupiditate hic necessitatibus placeat ex quia in aliquid reprehenderit architecto officiis nostrum recusandae, impedit
              qui. Alias quaerat nobis eveniet nesciunt quo minus maxime suscipit, ipsum velit, quisquam pariatur veritatis, 
              architecto necessitatibus ducimus explicabo ipsam totam quidem? Consequatur, corrupti. Quia, facere ipsam. Maxime 
              excepturi, numquam pariatur incidunt necessitatibus modi laborum dignissimos neque explicabo! Ullam enim fuga consectetur
               tempore nemo autem officia repellat, illo totam?</p>
               <br />
               <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque, excepturi! Voluptatum illum tempore repellat 
                nesciunt accusamus est dolores. Explicabo, voluptatum. Impedit rem nesciunt, obcaecati adipisci inventore nostrum 
                blanditiis voluptatibus. Unde minima tempora, ab eos doloribus soluta ea quis odio sequi molestias reprehenderit
                 numquam reiciendis eveniet ullam magni labore cupiditate. Vitae molestias atque blanditiis aliquam modi, eaque 
                 laudantium dolore dolores enim cum porro totam magnam doloremque tempore, saepe ducimus soluta animi at ipsam. 
                 Explicabo, voluptatum maxime? Provident ipsam sapiente aliquid eos.</p>
        </div>
        

    );
};

export default About;